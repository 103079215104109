/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useState } from "react";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MultiSelect = ({
  data,
  onChange,
  style,
  label,
  register,
  errors,
  message,
  file,
}) => {
  const [selected, setSelected] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelected = typeof value === "string" ? value.split(",") : value;
    if (!file && selected.length >= 3 && !selected.includes(value)) {
      return;
    }
    const uniqueArray = newSelected.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        newSelected.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    setSelected(uniqueArray);
    onChange(uniqueArray);
  };

  const handleDelete = (valueToDelete) => () => {
    const newSelected = selected.filter(
      (value) => value.value !== valueToDelete.value
    );
    setSelected(newSelected);
    onChange(newSelected);
  };

  const isSelected = (item) =>
    selected.find((selectedItem) => selectedItem.value === item.value) !==
    undefined;

  const isSelecteFile = (item) =>
    selected.find((selectedItem) => selectedItem.Id === item.Id) !== undefined;
  return (
    <div className="flex flex-col gap-2 font-main">
      <label className="font-medium text-[#404040]">{label}</label>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput label="Select" {...register} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value.value}
                label={value.label || value.Name}
                deleteIcon={
                  <CloseIcon onMouseDown={(event) => event.stopPropagation()} />
                }
                onDelete={handleDelete(value)}
              />
            ))}
          </Box>
        )}
        sx={{ width: "100%", ...style }}
      >
        {data?.map((item) => (
          <MenuItem key={item.value} value={item}>
            <Checkbox checked={file ? isSelecteFile(item) : isSelected(item)} />
            <ListItemText primary={item.label || item.Name} />
          </MenuItem>
        ))}
      </Select>
      {errors && <p className="text-red-500 text-sm">{message}</p>}
    </div>
  );
};

export default MultiSelect;
