/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from "zod";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import RadioInput from "../../../components/RadioInput";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextAreaInput";
import ImageUpload from "../../../components/ImageUpload";

export default function NewBenefit() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    responsibleUsers: z.any(),
    file: z
      .any()
      .refine((files) => files.length == 1, "Arquivo é obrigatório.")
      .refine(
        (files) => files?.[0]?.size <= 10000000,
        "Tamanho máximo de 10MB."
      ),
    option: z.any(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;
  const [selectedValueInput, setSelectedValueInput] =
    useState<string>("Novo benefício");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      name: "",
      responsibleUsers: "",
      file: "",
      option: "Novo benefício",
    },
  });

  function handleSubmitForm(data: NewChannelsSchema) {
    if (isEdit) {
    } else {
      navigate(-1);
    }
  }

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit[0].name);
    }
  }, [isEdit, dataEdit, setValue]);

  const handleChange = (value: string) => {
    console.log("entrei");
    setSelectedValueInput(value);
  };

  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <div className="flex flex-col w-full gap-2">
          <div>
            <BreadcrumbsPage />
          </div>
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar benefício" : "Cadastrar benefício"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <div className="flex gap-4 sm:flex-col">
              <RadioInput
                label="Novo benefício"
                value="Novo benefício"
                onChange={handleChange}
                register={register("option")}
                // errors={errors.option}
                selectedValueInput={selectedValueInput}
              />
              <RadioInput
                label="Copiar dados de outro benefício"
                value="Copiar dados de outro benefício"
                onChange={handleChange}
                register={register("option")}
                // errors={errors.option}
                selectedValueInput={selectedValueInput}
              />
            </div>

            <div className="flex gap-6 font-main sm:flex-col ">
              <div className="w-2/4 sm:w-[100%]">
                <Input
                  label="Nome"
                  placeholder="Digite o nome do benefício"
                  type="text"
                />
              </div>

              <div className="flex gap-6 w-2/4 sm:flex-col w-[100%]">
                <Input
                  label="Total de pontos"
                  placeholder="Digite o total de pontos"
                  type="number"
                />
                <Input
                  label="Qtd. de unidades"
                  placeholder="Digite a qtd. de unidades"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 rounded-lg">
              <TextArea label="Descrição" />
            </div>
            <div className="flex flex-col gap-4 rounded-lg">
              <ImageUpload
                title="Imagem"
                onImageSelect={function (file: File): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10 sm:flex-col gap-6">
        <div>
          <Button
            title="Voltar"
            onClick={() => navigate(-1)}
            backGroundColor={"#FCFCFA"}
            color={"#F28E00"}
            borderRadius={8}
          />
        </div>
        <div className="flex gap-4 sm:flex-col">
          {!isEdit ? (
            <Button
              title={isEdit ? "Salvar como rascunho" : "Salvar como rascunho"}
              backGroundColor={"#4B5380"}
              color={"#FAFAFC"}
              borderRadius={8}
              type="submit"
              form="my-form"
            />
          ) : null}
          <Button
            title={isEdit ? "Salvar benefício" : "Cadastrar benefício"}
            backGroundColor={"#F28E00"}
            color={"#FAFAFC"}
            borderRadius={8}
            type="submit"
            form="my-form"
          />
        </div>
      </div>
    </div>
  );
}
