import { z } from "zod";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import IconSelector from "../../../components/IconSelector";
import Input from "../../../components/Input";
import MultiSelect from "../../../components/MultiSelect";
import Button from "../../../components/Button";
import UploadContract from "../../../components/Upload";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import FileApi from "../../../api/file/file";
import { ResponseUsers } from "./types";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import CustomToast from "../../../components/Toast";
import React from "react";

export default function NewFile() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;
  const [users, setUsers] = useState<ResponseUsers[]>([]);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);

  const handleToastSuccess = () => {
    return (
      <CustomToast message="Arquivo cadastrado com sucesso" type="success" />
    );
  };

  const handleIconSelect = (icon: React.ReactNode) => {
    if (React.isValidElement(icon) && icon.props?.src) {
      fetch(icon.props.src)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "icon.png", { type: blob.type });
          setSelectedIconFile(file);
        })
        .catch((error) => {
          console.error("Erro ao buscar o ícone", error);
        });
    } else {
      console.error("Ícone selecionado inválido.");
    }
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    responsibleUsers: z.any(),
    file: z
      .any()
      .refine((files) => files.length === 1, "Arquivo é obrigatório.")
      .refine(
        (files) => files?.[0]?.size <= 10000000,
        "Tamanho máximo de 10MB."
      ),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      name: "",
      responsibleUsers: "",
      file: "",
    },
  });

  async function handleSubmitForm(data: NewChannelsSchema) {
    const idValue = data.responsibleUsers.map(
      (user: { Id: number }) => user.Id
    );

    if (isEdit) {
      const id = "1";
      const response = await FileApi.editFile(
        id,
        selectedIconFile,
        data.file,
        data.name,
        data.responsibleUsers
      );
      navigate(-1);
      return response;
    } else {
      const response = await FileApi.postNewFile(
        selectedIconFile,
        data.file[0],
        data.name,
        idValue
      );
      handleToastSuccess();
      navigate(-1);
      return response;
    }
  }

  async function listUser() {
    try {
      const response = await FileApi.listUsers();
      console.log(response, "aqui");
      if (response) setUsers(response);
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }
  useEffect(() => {
    listUser();
  }, []);

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit[0].name);
    }
  }, [isEdit, dataEdit, setValue]);

  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <div className="flex flex-col w-full gap-2">
          <div>
            <BreadcrumbsPage />
          </div>
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar arquivo" : "Cadastrar arquivo"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <div className="flex gap-4">
              <div>
                <IconSelector onIconSelect={handleIconSelect} />
              </div>

              <div className="w-full">
                <Input
                  label="Nome"
                  type="text"
                  placeholder="Digite o nome do arquivo"
                  register={register("name")}
                  errors={errors.name}
                />
              </div>
            </div>

            <div className="font-main">
              <div className="font-medium text-[#404040]">
                <p>Arquivo</p>
              </div>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <>
                    <UploadContract
                      name="file"
                      onUpload={(files) => {
                        field.onChange(files);
                      }}
                      hasFile={!!field.value}
                      error={errors.file}
                    />
                    {errors.file && (
                      <p
                        style={{
                          color: "#c93a2f",
                          marginTop: "-10px",
                          fontSize: "0.75rem",
                        }}
                      >
                        {String(errors.file.message)}
                      </p>
                    )}
                  </>
                )}
              />
              <p style={{ color: "#6D7882", fontSize: "14px" }}>
                Tamanho máximo 10Mb
              </p>
            </div>

            <div className="flex flex-col gap-4 rounded-lg">
              <Controller
                name="responsibleUsers"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Usuários"
                    data={users}
                    onChange={(values: SetStateAction<never[]>) => {
                      field.onChange(values);
                      handleMultiSelectChange(values);
                    }}
                    style={{ width: "100%" }}
                    register={field}
                    errors={errors.responsibleUsers}
                    message="Campo obrigatório"
                    file={true}
                  />
                )}
              />
              {selectedValues.length > 0 ? (
                <div>
                  <p>{selectedValues.length} usuário(s) selecionado(s)</p>
                </div>
              ) : (
                <div className="bg-[#F4F5F7] p-2 px-4 rounded-md text-[#404040] font-main">
                  <p>Nenhum usuário selecionado</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10">
        <Button
          title="Voltar"
          onClick={() => navigate(-1)}
          backGroundColor={"#FCFCFA"}
          color={"#F28E00"}
          borderRadius={8}
        />
        <Button
          title={isEdit ? "Salvar arquivo" : "Cadastrar arquivo"}
          backGroundColor={"#F28E00"}
          color={"#FAFAFC"}
          borderRadius={8}
          type="submit"
          form="my-form"
        />
      </div>
    </div>
  );
}
