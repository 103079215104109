/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import CicleWhite from "../../assets/icons/circle-white.svg";
import CircleGreenLigth from "../../assets/icons/circle-green-ligth.svg";
import { UseFormRegisterReturn, FieldError } from "react-hook-form";

interface RadioInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  register?: UseFormRegisterReturn;
  errors?: FieldError;
  selectedValueInput: string;
}

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  value,
  onChange,
  register,
  errors,
  selectedValueInput,
}) => {
  return (
    <label
      className={`flex gap-[10px] font-main items-center p-2 bg-[#FCFDF8] border rounded cursor-pointer mb-2 ${
        selectedValueInput === value ? "border-[#B6BA3A]" : "border-transparent"
      } ${selectedValueInput === value ? "font-bold" : "font-normal"}`}
    >
      <input
        type="radio"
        value={value}
        checked={selectedValueInput === value}
        onChange={() => onChange(value)}
        className="hidden"
        {...register}
      />
      {selectedValueInput === value ? (
        <img src={CircleGreenLigth} />
      ) : (
        <img src={CicleWhite} />
      )}
      {label}
      {errors && <span className="text-red-500 ml-2">{errors.message}</span>}
    </label>
  );
};

export default RadioInput;
