import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../components/Table/ActionsMenu";
import { IFiles } from "./types";

interface IRow {
  Icon: string;
  Name: string;
  category: number;
}

export function getColumns(
  toggleIsEdit?: ((row: IFiles) => void) | null,
  deleteHotelChain?: ((row: IFiles) => void) | null,
  onDownload?: ((row: IFiles) => void) | null,
  exist?: boolean
) {
  const columns = [
    {
      name: "Ícone",
      selector: (row: IRow) => <img src={row.Icon} alt="Icone" />,
    },
    {
      name: "Título",
      selector: (row: IRow) => <span>{row.Name}</span>,
    },
    {
      width: "60px",
      cell: (row: IFiles) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDownload={onDownload ? () => onDownload(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  if (exist) {
    columns.splice(2, 0, {
      name: "Status",
      selector: (row: IRow) => (
        <FormGroup>
          {row.category === 1 ? (
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Ativo"
              disabled
            />
          ) : (
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Inativo"
              disabled
            />
          )}
        </FormGroup>
      ),
    });
  }

  return columns;
}
