/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from "ky";
import { INumberOfChannels, ListChatsTopic, Users } from "./types";
// const API_URL = import.meta.env.VITE_APP_API_URL;

class ChannelAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewChannel(
    ChannelIcon: File | null,
    name: string,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    const networkUnitId = localStorage.getItem("selectedNetwork");

    formData.append("Title", name);
    formData.append("NetworkUnitId", `${networkUnitId}`);
    formData.append("Active", "true");
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File && ChannelIcon) {
      formData.append("ChannelIcon", ChannelIcon);
    }

    try {
      const headers = await this.getHeaders();
      const data = await ky.post(
        `https://dev.investor.api.vegait.com/api/1/chat-topic`,
        {
          body: formData,
          headers,
        }
      );
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo canal", error);
      throw error;
    }
  }

  public async listNumberOfChannels(): Promise<INumberOfChannels | undefined> {
    const headers = await this.getHeaders("application/json");
    const networkUnitId = localStorage.getItem("selectedNetwork");
    try {
      const response = await ky.get(
        `https://dev.investor.api.vegait.com/api/1/chat-topic-user/${networkUnitId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar números de comunicados", error);
      return undefined;
    }
  }

  public async listChannels(): Promise<ListChatsTopic[] | undefined> {
    const headers = await this.getHeaders("application/json");
    const userId = localStorage.getItem("userId");
    const networkUnitId = localStorage.getItem("selectedNetwork");

    try {
      const response = await ky.get(
        `https://dev.investor.api.vegait.com/api/1/chat-topic/list-channel/${userId}/${networkUnitId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar comunicados", error);
      return undefined;
    }
  }
  public async getResponsibleUsers(): Promise<Users | undefined> {
    const headers = await this.getHeaders("application/json");
    const networkUnitId = localStorage.getItem("selectedNetwork");
    try {
      const response = await ky.get(
        `https://dev.investor.api.vegait.com/api/1/user/admin/units/${networkUnitId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar usuarios responsaveis", error);
      return undefined;
    }
  }

  public async deleteChannel(deletingItem: number): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `https://dev.investor.api.vegait.com/api/1/chat-topic-user/${deletingItem}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar canal", error);
    }
  }

  public async editChannel(
    id: string,
    Icon: File | null,
    name: string,
    userIds?: string[],
    active?: boolean
  ): Promise<unknown> {
    const formData = new FormData();

    if (name) {
      formData.append("Title", name.toString());
    }
    if (userIds) {
      formData.append("userIds", JSON.stringify(userIds));
    }
    if (File && Icon) {
      formData.append("Icon", Icon);
    }
    if (typeof active !== "undefined") {
      formData.append("Active", active ? "true" : "false");
    }
    try {
      const headers = await this.getHeaders();
      const data = await ky.put(
        `https://dev.investor.api.vegait.com/api/1/chat-topic/${id}`,
        { body: formData, headers }
      );
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo canal", error);
      throw error;
    }
  }
}

export default new ChannelAPI();
