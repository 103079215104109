/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from "ky";
import { StatementResponse, StatementSchema } from "./statementModels";
// const API_URL = import.meta.env.VITE_APP_API_URL;

class StatmenteAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async createNewStatement(
    Schedule: string,
    Name: string,
    File: File | null,
    Text: string,
    users: number[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Schedule", Schedule);
    formData.append("Title", Name);
    formData.append("Text", Text);

    if (users?.length > 0) {
      users.forEach((users: any) =>
        formData.append("UserIds", users.Id.toString())
      );
    }
    if (File) {
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      const data = await ky.post(
        `https://dev.investor.api.vegait.com/api/1/notification`,
        {
          body: formData,
          headers,
        }
      );
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo canal", error);
      throw error;
    }
  }

  public async listStatement(
    statement: StatementSchema,
    page: number
  ): Promise<StatementResponse[] | undefined> {
    const headers = await this.getHeaders("application/json");

    let active: boolean | undefined;
    const status = statement.status?.map((x) => x.value);

    if (status && status.length > 0 && !status.includes("todos")) {
      active = status.includes("ativos")
        ? true
        : status.includes("inativos")
        ? false
        : undefined;
    }

    const storedNetworks = localStorage.getItem("networks");
    const parsedNetworks = storedNetworks ? JSON.parse(storedNetworks) : null;

    if (!parsedNetworks || parsedNetworks.length === 0) {
      console.error("Nenhuma rede encontrada no localStorage");
      return undefined;
    }

    const payload: any = {
      Active: active,
      NetworkUnitId: parsedNetworks[0].Id,
      Title: statement.title,
      PageSize: page,
      OffSet: 1,
    };

    if (statement.startDate) {
      payload.StartDate = statement.startDate;
    }
    if (statement.endDate) {
      payload.EndDate = statement.endDate;
    }

    try {
      const response = await ky
        .post(
          `https://dev.investor.api.vegait.com/api/1/notification/filter-notification`,
          {
            json: payload,
            headers,
          }
        )
        .json<StatementResponse[]>();

      return response;
    } catch (error) {
      console.error("Erro ao listar comunicados", error);
      return undefined;
    }
  }

  public async deleteStatement(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `https://dev.investor.api.vegait.com/api/1/notification/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editStatement(
    id: string,
    Schedule: string,
    Name: string,
    File: File | null,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Schedule", Schedule);
    formData.append("Name", Name);
    formData.append("Schedule", Schedule);
    formData.append("Schedule", Schedule);
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File) {
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      headers["Content-Type"] = "multipart/form-data";
      const data = await ky.put(
        `https://dev.investor.api.vegait.com/api/1/notification/${id}`,
        { body: formData, headers }
      );
      return data;
    } catch (error) {
      console.error("Erro ao editar comunicado", error);
      throw error;
    }
  }
}

export default new StatmenteAPI();
