/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import SelectEnterprise from "../../../components/SelectEnterprise";
import ChatIcon from "../../../assets/icons/chat.svg";
import DotsIcon from "../../../assets/icons/dots-vertical.svg";
import SendIcon from "../../../assets/icons/send-icon.svg";
import ModalFilter from "../../../components/Modal";
import { useEffect, useState } from "react";
import MessagesAPI from "../../../api/messages/messages";

export default function NewChannel() {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [useDataChat, setUseDataChat] = useState([]);
  const [messages, setMessages] = useState([]);
  const UserId = localStorage.getItem("userId");

  async function listChatsTopics() {
    try {
      const response = await MessagesAPI.listChatsTopics();
      if (response) setUseDataChat(response);
    } catch (error) {
      console.error("Erro ao listar chats", error);
    }
  }
  async function sendMessage() {
    try {
      await MessagesAPI.sendMessage(
        selectedConversation.ChatThreads,
        message,
        selectedConversation.chattopicid
      );
      setMessage("");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          UserId: UserId,
          Message: message,
        },
      ]);
      fetchMessages(selectedConversation.ChatThreadsId);
    } catch (error) {
      console.error("erro ao enviar mensagem para o usuário de destino", error);
    }
  }

  const handleSelectConversation = (value) => {
    setSelectedConversation(value);
    fetchMessages(value.ChatThreads);
  };

  const fetchMessages = async (chatThreadId) => {
    if (chatThreadId) {
      try {
        const response = await MessagesAPI.getMessagesChatPaginated(
          chatThreadId,
          1
        );
        setMessages(response);
      } catch (error) {
        console.error("Erro ao listar conversas do chat", error);
      }
    }
  };

  useEffect(() => {
    listChatsTopics();
  }, []);

  return (
    <div className="flex flex-col pl-10">
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Mensagens"
            subTitle="Visualize um histórico de conversa e responda aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <SelectEnterprise />
        </div>
      </div>

      <div className="flex gap-6">
        <div className="py-4 px-4 rounded-lg bg-white shadow-2xl w-[352.33px]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <Input label="" placeholder="Pesquisar" type="text" icon={true} />
              <ModalFilter />
            </div>
            <div className="scroll-container p-2 px-4 border-b-2 border-[#E3E7FF] max-h-screen overflow-y-auto">
              {useDataChat.map((value) => (
                <div
                  key={value.ChatThreads}
                  className="p-2 px-4 border-b-2 border-[#E3E7FF] cursor-pointer"
                  onClick={() => handleSelectConversation(value)}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center">
                        <p className="text-[#404040] font-semibold text-base">
                          {value.Title}
                        </p>
                        <div className="text-[#8A97B1] flex items-center gap-2 p-1 px-2 rounded-[48px] border border-[#E3E7FF)]">
                          {value.Title}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between text-xs text-[#6D7882] font-normal">
                      <p>
                        {value?.Message?.length > 30
                          ? value.Message.substring(0, 30) + "..."
                          : value.Message}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm ">
          {selectedConversation ? (
            <div className="flex flex-col justify-between p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm">
              <div className="flex justify-between border-b-2 border-[#E3E7FF] text-[#404040]">
                <h2>{selectedConversation.Title}</h2>
                <img src={DotsIcon} alt="" />
              </div>
              <div className="w-full h-full flex flex-col p-4">
                {messages.map((item, index) => (
                  <div key={index} className="flex flex-col h-full mb-2">
                    {item.UserId == UserId ? (
                      <div className="flex justify-end mb-1 p-2 rounded">
                        <div
                          className="bg-[#2E406B]"
                          style={{
                            padding: "8px",
                            gap: "10px",
                            borderRadius: "8px",
                          }}
                        >
                          <p className="max-w-xs break-words overflow-hidden overflow-ellipsis text-white font-poppins">
                            {item.Message}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-start">
                        <p className="max-w-xs break-words overflow-hidden overflow-ellipsis">
                          {item.Message}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex gap-4 justify-between items-center bg-[#F4F5F7] p-4">
                <div className="w-full">
                  <Input
                    label={""}
                    placeholder={"Digite sua mensagem..."}
                    type={"text"}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="bg-[#F28E00] p-2 px-4 rounded-lg"
                    onClick={sendMessage}
                  >
                    <img src={SendIcon} alt="" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm">
              <img src={ChatIcon} alt="" />
              <p className="text-[#404040] font-bold">Boas-vindas</p>
              <p className="text-[#8A97B1] text-center">
                Para começar a enviar mensagens, primeiro selecione uma conversa
                na lista ao lado.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
