/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Input from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import SelectEnterprise from "../../components/SelectEnterprise";
import { Grid2 } from "@mui/material";
import { useEffect, useState } from "react";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { getColumns } from "./columns";
import DeleteModal from "../../components/DeleteModal";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import statement from "../../api/statement/statement";

export default function Statement() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingContract, setDeletingContract] = useState<null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [statementData, setStatementData] = useState<any>([]);

  const defaultFormData = {
    title: "",
    status: [],
    startDate: "",
    endDate: "",
  };

  const [initialData, setInitialData] = useState(defaultFormData);

  const dataEdit = [{ name: "teste", category: 1 }];
  const data = [
    { value: "todos", label: "Todos" },
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleMultiSelectChange = (values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedValues(uniqueValues);
  };

  const editChannel = () => {
    navigate("/editar-comunicado", { state: { isEdit: true, data: dataEdit } });
  };

  async function deleteItem(deletingItem) {
    const response = await statement.deleteStatement(deletingItem.id);
    setDeletingContract(null);
  }

  const newChannelCreateSchema = z.object({
    title: z.string().optional(),
    status: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
        })
      )
      .optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: initialData,
  });
  const handleReset = () => {
    listComunicados(
      {
        title: "",
        status: [],
        startDate: "",
        endDate: "",
      },
      pageSize
    );
    reset(initialData);
  };
  async function listComunicados(data: NewChannelsSchema, page: number) {
    const response = await statement.listStatement(data, page);
    const formattedData = response?.map((x) => {
      return {
        id: x.id,
        name: x.title,
        category: dayjs(x.scheduling).format("DD/MM/YYYY"),
      };
    });
    setStatementData(formattedData);
    return response;
  }

  const onSubmit = async (data: NewChannelsSchema) => {
    await listComunicados(data, pageSize);
  };

  useEffect(() => {
    listComunicados(
      {
        title: "",
        status: [],
        startDate: "",
        endDate: "",
      },
      pageSize
    );
  }, [pageSize]);

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Comunicados"
            subTitle="Visualize, crie e gerencie os comunicados disponibilizados aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end  gap-5 w-1/2">
          <div>
            <SelectEnterprise />
          </div>

          <div>
            <Button
              title="+ Novo comunicado"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/cadastrar-comunicado")}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6">
          <div className="w-2/4">
            <Input
              label="Título"
              placeholder="Digite o nome do canal"
              type="text"
              register={register("title")}
            />
          </div>
          {/* 
          <div className="w-2/4">
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Status"
                  data={data}
                  onChange={(values: SetStateAction<never[]>) => {
                    field.onChange(values);
                    handleMultiSelectChange(values);
                  }}
                  style={{ width: "100%" }}
                  register={field}
                  errors={errors.responsibleUsers}
                  message="Campo obrigatório"
                  file={false}
                />
              )}
            />
          </div>
          */}
        </div>
        <div className="flex gap-6">
          {/*
          <div className="w-2/4">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  name="startDate"
                  label="Período de publicação"
                  placeholder="Período de publicação"
                  type="date"
                />
              )}
            />
          </div>
           <div className="flex justify-end items-end w-2/4">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  name="endDate"
                  label=""
                  placeholder="Período de publicação"
                  type="date"
                />
              )}
            />
          </div>
           */}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={() => reset(handleReset)}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">{statementData.length}</span> itens
              cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        <Table
          data={statementData}
          columns={getColumns(editChannel, setDeletingContract, false)}
          hidePagination
          perPage={pageSize}
        />
      </div>
      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.name}
          confirm={() => deleteItem(deletingContract)}
          cancel={() => setDeletingContract(null)}
        />
      )}
    </Grid2>
  );
}
