import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../components/Table/ActionsMenu";

interface RowData {
  name: string;
  category: number | string;
}

export function getColumns(
  toggleIsEdit?: ((row: RowData) => void) | null,
  deleteHotelChain?: ((row: RowData) => void) | null,
  exist?: boolean
) {
  const columns = [
    {
      name: "Título",
      selector: (row: RowData) => <span>{row.name}</span>,
    },
    {
      name: "Publicação",
      selector: (row: RowData) => <span>{row.category as string}</span>,
    },
    {
      name: "Ações",
      width: "60px",
      cell: (row: RowData) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  if (exist) {
    columns.splice(2, 0, {
      name: "Status",
      selector: (row: RowData) => {
        return (
          <FormGroup>
            {row.category === 1 ? (
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Ativo"
              />
            ) : (
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Inativo"
              />
            )}
          </FormGroup>
        );
      },
    });
  }

  return columns;
}
