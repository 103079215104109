import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface Hotel {
  Id: number;
  Name: string;
}

export default function SelectEnterprise() {
  const [hotels, setHotels] = useState<{ label: string; id: number }[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<{
    label: string;
    id: number;
  } | null>(null);

  useEffect(() => {
    const fetchHotels = async () => {
      const storedNetworks = localStorage.getItem("networks");
      if (storedNetworks) {
        const parsedNetworks: Hotel[] = JSON.parse(storedNetworks);

        const formattedHotels = parsedNetworks.map((hotel) => ({
          label: hotel.Name,
          id: hotel.Id,
        }));

        setHotels(formattedHotels);

        if (formattedHotels.length > 0) {
          setSelectedHotel(formattedHotels[0]);
        }
      }
    };

    fetchHotels();
  }, []);

  return (
    <div className="w-[352px] h-[56px] border-l-4 border-[#076BEF] font-main sm:w-[100%]">
      <span className="text-sm top-3 px-1 text-[#076BEF]">Você está vendo</span>
      <Autocomplete
        sx={{
          borderBottom: "none",
        }}
        options={hotels}
        value={selectedHotel}
        onChange={(event, newValue) => {
          setSelectedHotel(newValue);
        }}
        getOptionLabel={(option) => option.label}
        id="enterprise-select"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            sx={{
              paddingLeft: "5px",
            }}
          />
        )}
      />
    </div>
  );
}
