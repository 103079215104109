/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ky from "ky";
import { IListUsers } from "./types";
// const API_URL = import.meta.env.VITE_APP_API_URL;

class FileApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewFile(
    Icon: File | null,
    File: File | null,
    Name: string,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Name", Name);
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File && Icon) {
      formData.append("Icon", Icon);
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      const data = await ky.post(
        `https://dev.investor.api.vegait.com/api/1/file`,
        {
          body: formData,
          headers,
        }
      );
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo arquivo", error);
      throw error;
    }
  }

  public async listFile(pageSize: number, offSet: number): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork");

    try {
      const response = await ky.get(
        `https://dev.investor.api.vegait.com/api/1/file/${selectedNetwork}/${pageSize}/${offSet}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar arquivos", error);
    }
  }

  public async listUsers(): Promise<IListUsers[] | undefined> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork");

    try {
      const response = await ky.get(
        `https://dev.investor.api.vegait.com/api/1/user/network-unit/investor/${selectedNetwork}`,
        { headers }
      );

      return response.json();
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }

  public async deleteFile(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `https://dev.investor.api.vegait.com/api/1/file/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editFile(
    id: string,
    Icon: File | null,
    File: File | null,
    name: string,
    userIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("name", name.toString());
    formData.append("userIds", JSON.stringify(userIds));

    if (File && Icon) {
      formData.append("Icon", Icon);
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      headers["Content-Type"] = "multipart/form-data";
      const data = await ky.post(
        `https://dev.investor.api.vegait.com/api/1/file/${id}`,
        {
          body: formData,
          headers,
        }
      );
      return data;
    } catch (error) {
      console.error("Erro ao editar arquivo", error);
      throw error;
    }
  }
}

export default new FileApi();
