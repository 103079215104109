import ActionsMenu from "../../../components/Table/ActionsMenu";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export function getColumns(
  toggleIsEdit?: ((row: undefined) => void) | null,
  deleteHotelChain?: ((row: undefined) => void) | null,
  toggleStatus?: ((row: { Active: boolean }) => void) | null
) {
  return [
    {
      name: "Ícone",
      selector: (row: { ChannelIcon: string }) => (
        <img src={row.ChannelIcon} alt="icon" />
      ),
    },
    {
      name: "Nome",
      selector: (row: { Title: string }) => <span>{row.Title}</span>,
    },
    {
      name: "Status",
      selector: (row: { Active: boolean; Id: string }) => {
        const handleChange = async () => {
          if (row && toggleStatus) {
            await toggleStatus(row);
          }
        };
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={row.Active}
                  edge="start"
                  onChange={handleChange}
                />
              }
              label={row.Active ? "Ativo" : "Inativo"}
            />
          </FormGroup>
        );
      },
    },
    {
      name: "Ações",
      width: "60px",
      cell: (row: undefined) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];
}
